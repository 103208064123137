import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Modal, notification } from 'antd'

import { toggleAccount } from '../../common/request'
import ResponseCode from '../../common/response-code'
import Styles from './ToggleAccountModal.module.scss'
import { useSearchParams } from 'react-router-dom'

const ToggleAccountModal = forwardRef((props, ref) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useSearchParams()

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const toggleAccountAction = (selectItem) => {
    if (props.currentAccount.accountId === selectItem.accountId) {
      return
    }

    setLoading(true)
    toggleAccount({ accountId: selectItem.accountId }).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success && res.data) {
        sessionStorage.setItem('access_token', res.data.token)
        sessionStorage.setItem('token_header', res.data.tokenHead)
        notification.success({
          message: '操作成功'
        })
        hideModal()
        setSearch('')

        window.location.reload()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，切换账号失败'
      })
    })
  }

  const getRoleItemClass = (isActive) => {
    if (isActive) {
      return [Styles.accountItem, Styles.isActive].join(' ')
    } else {
      return Styles.accountItem
    }
  }

  const generateRoleItem = () => {
    return props.accountList.map(account => {
      return <div
        className={ getRoleItemClass(props.currentAccount.accountId === account.accountId) }
        key={ account.accountId }
        onClick={ toggleAccountAction.bind(this, account) }
      >
        { `${ account.name }(${ account.roleName })` }
      </div>
    })
  }

  return (<>
    <div ref={ ref }>
      <Modal
        title={ '切换身份' }
        open={ modalVisible }
        confirmLoading={ loading }
        onCancel={ hideModal }
        footer={ [] }
      >
        <div className={ 'exam-list-wrap' }>
          { generateRoleItem() }
        </div>
      </Modal>
    </div>
  </>)
})

export default ToggleAccountModal
