import React from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { uploadEditorFile } from '../common/request'
import { Modal } from 'antd'
import ResponseCode from '../common/response-code'

const regx = /("|')width:(.|\n|\r)*?(pt|cm);/g
const sizeList = [8, 10, 10.5, 12, 14, 16, 18, 22, 24, 26, 28, 30, 32, 36, 40, 44, 48, 64, 72].map(item => {
  return `${ item }=${ item }pt`
})

const replaceContent = (content) => {
  let template = content
  content.match(regx)?.forEach(item => {
    template = template.replaceAll(`style=${ item }`, `style=${item.slice(0, 1)}max-${item.replace(/("|')/g, '')}${item.replace(/("|')/g, '')}`)
  })

  template = template.replaceAll(/(<o:p>(&nbsp;)*<\/o:p>)/g, '<br/>')
  template = template.replaceAll('mso-bidi-font-size', 'font-size')

  return  template
}

function AppEditor (props) {
  return (<>
    <Editor
      init={ {
        disabled: props.disabled,
        promotion: false,
        table_resize_bars: false,
        allow_html_in_named_anchor: true,
        language: 'zh-Hans',
        menubar: 'false',
        plugins: 'preview importcss searchreplace ' +
          'autolink autosave pagebreak advlist lists ' +
          'charmap directionality fullscreen link image codesample table',
        toolbar: ['undo redo fontfamily fontsize forecolor backcolor bold italic underline strikethrough ' +
        'fontselect fontsizeselect formatselect alignleft aligncenter alignright alignjustify ' +
        'outdent indent numlist bullist link table'],
        font_family_formats: '宋体; 黑体; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
        font_size_formats: sizeList.join(' '),
        height: props.height || '600px',
        paste_webkit_styles: 'all',
        content_style: 'body { font-family:"宋体"; font-size:12pt } strong { font-family:Helvetica,Arial,sans-serif!important; }',
        content_css: '/editor.css',
        paste_preprocess: function (plugin, args) {
          args.content = replaceContent(args.content)
        },
        file_picker_callback: (callback, value, meta) => {
          const input = document.createElement('input')
          input.setAttribute('type', 'file')
          input.click()
          input.onchange = function () {
            const file = this.files[0]
            const formData = new FormData()
            formData.append('file', file, file.name)

            uploadEditorFile(formData).then(res => {
              if (res.code === ResponseCode.success) {
                callback(res.data.filePath, { text: res.data.fileName })
              } else {
                Modal.error({
                  title: '错误',
                  content: res.msg,
                  zIndex: 1600
                })
              }
            }).catch(err => {
              console.error(err)
              Modal.error({
                title: '错误',
                content: '网络错误，上传文件失败',
                zIndex: 1600
              })
            })
          }
        }
      } }
      { ...props }
    />
  </>)
}

export default AppEditor
