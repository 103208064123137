import React, { useEffect, useState } from 'react'
import { Button, Dropdown, Empty, Modal, notification, Space, Spin, Upload } from 'antd'
import { DownOutlined, RightOutlined } from '@ant-design/icons'

import Styles from '../create-exam-batch/ExamBatch.module.scss'
import ExaminationLocationTable from './ExaminationLocationTable'
import {
  downloadDoorStickersHtmlToPdfBatch,
  downloadExaminationRoomExamineeAllocateFile,
  downloadExaminationRoomFile, downloadExamineeTableBatch, downloadNonStandardAllocateRoom,
  downloadSeatHtmlToPdfBatch,
  downloadTableStickersHtmlToPdfBatch,
  uploadNonStandardAllocateRoom
} from '../../../common/request'
import ResponseCode from '../../../common/response-code'
import router from '../../../routers/router'
import { useSelector } from 'react-redux'

function ExaminationBatchRoomItem ({ batchInfo, updateBatchList, componentType }) {
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const envInfo = useSelector(state => state.env.envInfo)
  const [batchItemClass, setBatchItemClass] = useState('')
  const [batchCollapse, setBatchCollapse] = useState(true)
  const [locationList, setLocationList] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (batchCollapse) {
      setBatchItemClass([Styles.batchItem, Styles.isCollapseActive].join(' '))
    } else {
      setBatchItemClass(Styles.batchItem)
    }
  }, [batchCollapse])

  const downloadMenuProps = [
    {
      label: '考生安排表',
      key: '0'
    },
    {
      label: '考场统计表',
      key: '1'
    },
    {
      label: '考生浏览表',
      key: '2'
    },
    {
      label: '座次表',
      key: '3'
    },
    {
      label: '桌贴',
      key: '4'
    },
    {
      label: '门贴',
      key: '5'
    }
  ]

  /**
   * 切换考试批次折叠状态
   *
   * 该函数用于反转当前的考试批次折叠状态。当调用此函数时，考试批次折叠状态将发生反转，
   * 如果之前是折叠状态，则会变为展开状态，反之亦然。
   *
   * @returns {void} 无返回值
   */
  const toggleBatchCollapse = () => {
    setBatchCollapse(!batchCollapse)
  }

  /**
   * 更新考场位置列表
   *
   * 该函数用于更新存储考场位置信息的列表。它接受一个列表作为参数，并将该列表赋值给 `locationList` 变量。
   *
   * @param {Array} list - 包含考场位置信息的数组。每个元素代表一个考场。
   * @returns {void} 无返回值
   */
  const updateLocationList = (list) => {
    setLocationList(list)
  }

  const beforeUpload = () => {
    setLoading(true)
  }

  /**
   * 下载考场分配模板的函数。
   *
   * 此函数用于生成并下载非标准考场分配的Excel模板。它首先阻止事件的进一步传播，
   * 然后根据当前选择的考试和批次信息构建请求参数。通过调用下载接口，获取到模板内容后，
   * 使用Blob对象和URL.createObjectURL方法创建一个可下载的链接，并模拟点击这个链接来触发下载。
   * 如果下载过程中发生错误，会显示一个错误模态框，并在控制台输出错误信息。
   *
   * @param {Event} e - 事件对象，用于调用stopPropagation方法阻止事件传播。
   * @returns {void} 无返回值
   */
  const downloadTemplate = (e) => {
    e.stopPropagation()
    const params = {
      examId: selectedExam.id,
      batchId: batchInfo.batchId
    }

    downloadNonStandardAllocateRoom(params).then(res => {
      setLoading(false)
      let blob = new Blob([res], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; application/octet-stream'
      })
      let objectUrl = URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = objectUrl
      a.download = '考场分配模板'
      a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))
      window.URL.revokeObjectURL(blob)
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，下载考场分配模板失败'
      })
    })
  }

  const uploadTemplateSuccess = ({ file }) => {
    const res = file.response

    if (res) {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        notification.success({
          message: '操作成功'
        })
        updateBatchList?.()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }
  }

  /**
   * 根据考试类型生成考场内容。
   *
   * @param {number} type - 考试类型的标识，用于确定生成的内容。
   * @returns {React.Element} - 根据考试类型生成的React元素，可以是上传文档的组件或者考场位置表。
   */
  const generateExamLocationContent = (type) => {
    let content
    switch (type) {
      case 3:
        content = (
          <div className={ 'd-flex align-items-center justify-content-center flex-column' }>
            <Empty description={ '' }></Empty>
            <Upload
              name={ 'file' }
              action={ `${ envInfo.picDomain }/${ uploadNonStandardAllocateRoom }` }
              headers={ {
                authorization: window.sessionStorage.getItem('access_token')
              } }
              maxCount={ 1 }
              data={ {
                examId: selectedExam.id,
                batchId: batchInfo.batchId
              } }
              showUploadList={ false }
              beforeUpload={ beforeUpload }
              onChange={ uploadTemplateSuccess }
            >
              <Button
                type={ 'primary' }
                ghost
              >上传考场分配文档</Button>
            </Upload>
          </div>
        )
        break
      case 4:
      case 5:
      case 6:
        content = (
          <ExaminationLocationTable
            batchInfo={ batchInfo }
            componentType={ componentType }
            updateLocationList={ updateLocationList }
          ></ExaminationLocationTable>
        )
        break
    }

    return content
  }

  const showDownloadDropdownMenu = (e) => {
    e.stopPropagation()
  }

  const downloadFileAction = (data) => {
    const e = data.domEvent
    e.stopPropagation()
    let request
    let params = {
      examId: batchInfo.examId,
      batchId: batchInfo.batchId,
      locationIds: locationList.map(item => item.locationId)
    }

    switch (data.key) {
      case '0':
        request = downloadExamineeTableBatch
        break
      case '1':
        request = downloadExaminationRoomFile
        break
      case '2':
        request = downloadExaminationRoomExamineeAllocateFile
        break
      case '3':
        request = downloadSeatHtmlToPdfBatch
        break
      case '4':
        request = downloadTableStickersHtmlToPdfBatch
        break
      case '5':
        request = downloadDoorStickersHtmlToPdfBatch
        break
      default:
        Modal.error({
          title: '错误',
          content: '文件类型不存在'
        })
    }

    downloadFile(request, params)
  }

  const downloadFile = (request, params) => {
    setLoading(true)
    request(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        Modal.confirm({
          title: '操作成功',
          content: '下载任务已添加，是否跳转到下载中心查看',
          onOk: () => {
            const siteId = sessionStorage.getItem('siteId')
            router.navigate(`/${ siteId }/exam/download-center`)
          }
        })
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，下载考场安排数据失败'
      })
    })
  }

  const downloadBtnVisible = (visible) => {
    if (visible) {
      return (
        <Dropdown menu={ {
          items: downloadMenuProps,
          onClick: downloadFileAction
        } }>
          <Button
            type={ 'primary' }
            size={ 'small' }
            onClick={ showDownloadDropdownMenu }
          >
            <Space>
              下载
              <DownOutlined/>
            </Space>
          </Button>
        </Dropdown>
      )
    } else {
      return <Space>
        <Button
          type={ 'primary' }
          onClick={ downloadTemplate }
        >下载模板</Button>
      </Space>
    }
  }

  return (<>
    <Spin spinning={ loading }>
      <div className={ batchItemClass }>
        <div className={ Styles.batchItemHeader } onClick={ toggleBatchCollapse }>
          <Space size={ 64 }>
            <div className={ 'text-primary' }>{ batchInfo.batchName }</div>
            <div>
              <span>考生人数：</span>
              <span>{ batchInfo.applyCount }</span>
            </div>
            <div>
              <span>考场数：</span>
              <span>{ batchInfo.roomNumber }</span>
            </div>
          </Space>

          <Space size={ 16 }>
            { downloadBtnVisible(batchInfo.generateCandidateNumber === 1) }
            <Button className={ 'ml-3' } size={ 'small' } type={ 'link' }>
              <RightOutlined/>
            </Button>
          </Space>

        </div>
        <div className={ Styles.batchItemBody }>
          { generateExamLocationContent(batchInfo.allocateProgress) }
        </div>
      </div>
    </Spin>
  </>)
}

export default ExaminationBatchRoomItem
