const generateImage = (path, height, rowNum) => {
  return `<div class="img-wrap" style="width: 100%;${ height }">
      <div class="img" style="background: url('${ path }') no-repeat"></div>
    </div>`
}

const getRegExp = (value) => {
  return `{{${ value }}}`
}

const baseStyle = `<style>
  p {
    height: auto;
    margin: 0;
  }
  table {
    margin: 0 auto;
  }
  td, tr {
    word-break: break-all!important;
    padding: 0!important;
  }
  .inner-table {
    width: 100%;
    font-family: inherit;
    font-size: inherit;
  }
  .inner-table thead {
    width: 100%
  }
  .inner-table thead tr > th {
    border-bottom: solid windowtext 1.0pt;
    font-size: inherit;
  }
  .inner-table tbody tr > td {
    font-size: inherit;
    padding: 4px 6px!important;
  }
  .inner-table tr:not(:last-child) > td {
    border-bottom: solid windowtext 1.0pt;
  }
  .inner-table tr > th:not(:last-child),
  .inner-table tr > td:not(:last-child) {
    border-right: solid windowtext 1.0pt;
  }
  .img-wrap {
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
  .img-wrap .img {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-size: contain !important;
    background-position: center !important;
  }
  .table-wrap {
    display: flex;
  }
</style>`

export const generateInterviewTicket = (template, fieldList, applyInfo, fileDomain) => {
  let html = `${ baseStyle }${ template || '' }
      `
  fieldList?.forEach(field => {
    if (field.fieldType === '6') {
      html = html.replaceAll(getRegExp(field.fieldCode), '{{img_@_placeholder}}')
      html.match(/<td[^>]*?>[^\/]*?{{img_@_placeholder}}(.|\n|\r)*?<\/td>/g)?.forEach(item => {
        const rowspanNumber = item.match(/rowspan="(\d*)"/)?.[1] || 1
        const matchResult = item.match(/height:(.[^(<|>)]*)(\d*)(pt|cm|px);/)
        const heightNumber = matchResult?.[1].trim()
        const heightStr = matchResult[0]?.replace(heightNumber, Number(heightNumber) * Number(rowspanNumber))
        html = html.replaceAll(/<p(.|\n|\r)[^>]*?><span(.|\n|\r)[^>]*?>((.|\n|\r)[^(<|>)]*?)?{{img_@_placeholder}}((.|\n|\r)[^(<|>)]*?)?<\/span><\/p>/g, generateImage(`${ fileDomain }/${ applyInfo[field.fieldCode] }`, heightStr, rowspanNumber))
      })
    } else {
      html = html.replaceAll(getRegExp(field.fieldCode), applyInfo[field.fieldCode] || '')
    }
  })

  html = html.replaceAll(/<strong/g, '<b')
  html = html.replaceAll(/<\/strong/g, '</b')

  return html
}
