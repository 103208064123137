import SelectExam from '../../components/select-exam/SelectExam'
import React, { useEffect, useState, useRef } from 'react'
import { Button, Col, ConfigProvider, Form, Input, Modal, Row, Select, Spin, Table } from 'antd'
import { useSelector } from 'react-redux'
import { examCheckStatus, payStatusMap } from '../../common/common'
import { getBatchExamineeApplyInfo, getExamineePrintList } from '../../common/request'
import ResponseCode from '../../common/response-code'
import PrintApplyFormTableModal from './PrintApplyFormTableModal'
import { throttle } from '../../plugins/utils'
import PrintAdmissionTicketModal from './PrintAdmissionTicketModal'
import PrintInterviewTicketModal from './PrintInterviewTicketModal'
import DeIdentification from '../../components/DeIdentification'

function ExamineePrint () {
  const [form] = Form.useForm()
  const printApplyFormTableModal = useRef()
  const printAdmissionTicketModal = useRef()
  const printInterviewTicketModal = useRef()
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [tableData, setTableData] = useState({})
  const [loadingTableData, setLoadingTableData] = useState(false)
  const [loadingExamList, setLoadingExamList] = useState(false)
  const [selectedTableItem, setSelectedTableItem] = useState([])

  const payStatusOptions = [
    {
      label: '全部',
      value: ''
    },
    {
      label: '未缴费',
      value: 0
    },
    {
      label: '缴费成功',
      value: 1
    },
    {
      label: '贫困生免费',
      value: 2
    }
  ]

  const saveStatusOptions = [
    {
      label: '全部',
      value: ''
    },
    ...Object.keys(examCheckStatus).reduce((prev, key) => {
      prev.push({ value: Number(key), label: examCheckStatus[key].label })
      return prev
    }, [])
  ]

  const takeExamCheckOptions = [
    {
      label: '全部',
      value: ''
    },
    {
      label: '未确认',
      value: 0
    },
    {
      label: '已确认',
      value: 1
    }
  ]

  const tableTypeOptions = [
    {
      label: '报名表',
      value: 1
    },
    {
      label: '笔试准考证',
      value: 2
    },
    {
      label: '面试准考证',
      value: 3
    }
  ]

  const columns = [
    {
      title: '报名序号',
      width: 140,
      fixed: 'left',
      key: 'applyNo',
      dataIndex: 'applyNo'
    },
    {
      title: '姓名',
      width: 100,
      fixed: 'left',
      dataIndex: 'examineesName',
      key: 'examineesName',
      render: (record) => {
        return (<DeIdentification value={ record } type={ 'name' }></DeIdentification>)
      }
    },
    {
      title: '身份证号',
      width: 170,
      dataIndex: 'idCard',
      key: 'idCard',
      render: (record) => {
        return (<DeIdentification value={ record } type={ 'idCard' }></DeIdentification>)
      }
    },
    {
      title: '报名点名称',
      width: 130,
      dataIndex: 'siteName',
      key: 'siteName'
    },
    {
      title: '报名点代码',
      width: 120,
      dataIndex: 'siteCode',
      key: 'siteCode'
    },
    {
      title: '报考单位',
      width: 170,
      dataIndex: 'unitName',
      key: 'unitName'
    },
    {
      title: '报考单位代码',
      width: 120,
      dataIndex: 'unitCode',
      key: 'unitCode'
    }
    , {
      title: '报考职位',
      width: 100,
      dataIndex: 'positionName',
      key: 'positionName'
    },
    {
      title: '报考职位代码',
      width: 120,
      dataIndex: 'positionCode',
      key: 'positionCode'
    },
    {
      title: '审核状态',
      fixed: 'right',
      width: 100,
      dataIndex: 'checkStatus',
      key: 'checkStatus',
      render: (record) => {
        return (<div className={ examCheckStatus[record]?.className }>{ examCheckStatus[record]?.label }</div>)
      }
    },
    {
      title: '支付状态',
      width: 100,
      fixed: 'right',
      dataIndex: 'payStatus',
      key: 'payStatus',
      render: (record) => {
        return (<div className={ payStatusMap[record]?.className }>{ payStatusMap[record]?.label }</div>)
      }
    }
  ]

  useEffect(() => {
    if (selectedExam.id) {
      getTableData()
    }
  }, [selectedExam, pageSize, pageNum])

  const getTableData = () => {
    form.validateFields().then(fields => {
      setLoadingTableData(true)
      const params = {
        ...fields,
        examId: selectedExam.id,
        orderBy: 'id',
        pageNum: pageNum,
        pageSize: pageSize,
        tableType: fields.tableType || 1
      }

      getExamineePrintList(params).then(res => {
        setLoadingTableData(false)
        if (res.code === ResponseCode.success) {
          setTableData(res.data)
        } else {
          Modal.error({
            title: '错误',
            content: res.msg
          })
        }
      }).catch(() => {
        setLoadingExamList(false)
        Modal.error({
          title: '错误',
          content: '网络错误，查询考生信息失败'
        })
      })
    })
  }

  const paginationChange = (num, size) => {
    setPageNum(num)
    setPageSize(size)
  }

  const onEnter = (e) => {
    if (e.keyCode === 13) {
      getTableData()
    }
  }

  const printBatch = () => {
    if (selectedTableItem.length === 0) {
      Modal.error({
        title: '错误',
        content: '未选择考生'
      })
      return
    }

    if (selectedTableItem.length > 10) {
      Modal.error({
        title: '错误',
        content: '单次打印数量不能超过10张'
      })
      return
    }
    const tableType = form.getFieldValue('tableType')

    switch (tableType) {
      case 1:
        getBatchExamineeApplyInfo({ ids: selectedTableItem.map(item => item.applyInfoId) }).then(res => {
          if (res.code === ResponseCode.success) {
            printApplyFormTableModal.current.showModal(res.data)
          }
        })
        break
      case 2:
        printAdmissionTicketModal.current.showModal(selectedTableItem)
        break
      case 3:
        printInterviewTicketModal.current.showModal(selectedTableItem)
        break
      default:
    }
  }

  const getTableColumns = (columnList) => {
    return columnList.filter(item => {
      if (selectedExam.isCheck === 0 && item.key === 'checkStatus') {
        return false
      } else if (selectedExam.payType === 0 && item.key === 'payStatus') {
        return false
      } else if (selectedExam.takeExamCheck === 0 && item.key === 'takeExamCheck') {
        return false
      } else {
        return true
      }
    })
  }

  return (
    <div>
      <SelectExam>
        <Spin spinning={ loadingExamList }>
          <div className={ 'd-flex align-items-center' }>
            <Form
              form={ form }
              initialValues={ {
                payStatus: '',
                saveStatus: '',
                takeExamCheck: '',
                tableType: 1
              } }
              onValuesChange={ throttle(getTableData, 500) }
            >
              <Row gutter={ 10 }>
                <Col span={ 6 }>
                  <Form.Item
                    label={ '考生姓名' }
                    name={ 'name' }
                    trigger={ 'onInput' }
                  >
                    <Input placeholder={ '输入考生姓名搜索' } onKeyUp={ onEnter }></Input>
                  </Form.Item>
                </Col>
                <Col span={ 6 }>
                  <Form.Item
                    label={ '身份证号' }
                    name={ 'idCard' }
                    trigger={ 'onInput' }
                  >
                    <Input placeholder={ '输入身份证号搜索' } onKeyUp={ onEnter }></Input>
                  </Form.Item>
                </Col>
                <Col span={ 6 }>
                  <Form.Item
                    label={ '报名序号' }
                    name={ 'examineesNumber' }
                    trigger={ 'onInput' }
                  >
                    <Input placeholder={ '输入报名序号搜索' } onKeyUp={ onEnter }></Input>
                  </Form.Item>
                </Col>
                {
                  selectedExam.payType === 1
                    ? <Col span={ 6 }>
                      <Form.Item label={ '支付状态' } name={ 'payStatus' }>
                        <Select options={ payStatusOptions }></Select>
                      </Form.Item>
                    </Col>
                    : <></>
                }
                {
                  selectedExam.takeExamCheck === 1
                    ? <Col span={ 6 }>
                      <Form.Item label={ '笔试确认状态' } name={ 'takeExamCheck' }>
                        <Select options={ takeExamCheckOptions }></Select>
                      </Form.Item>
                    </Col>
                    : <></>
                }
                {
                  selectedExam.isCheck === 1
                    ? <Col span={ 6 }>
                      <Form.Item label={ '审核状态' } name={ 'saveStatus' }>
                        <Select options={ saveStatusOptions }></Select>
                      </Form.Item>
                    </Col>
                    : <></>
                }
                <Col span={ 6 }>
                  <Form.Item label={ '表样类型' } name={ 'tableType' }>
                    <Select options={ tableTypeOptions }></Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>

          <div className={ 'my-3 d-flex align-items-baseline justify-content-between' }>
            <div className={ 'text-danger font-size-mini' }>一次最多可同时下载10条数据</div>
            <Button type={ 'primary' } onClick={ printBatch }>批量下载</Button>
          </div>

          <ConfigProvider componentSize={ 'small' }>
            <Table
              columns={ getTableColumns(columns) }
              loading={ loadingTableData }
              dataSource={ tableData.elements }
              rowSelection={ {
                type: 'checkbox',
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelectedTableItem(selectedRows)
                }
              } }
              scroll={ {
                x: 1360
              } }
              rowKey={ (record) => record.idCard }
              pagination={ {
                showTotal: (total) => {
                  return `共 ${ total } 条`
                },
                size: 'normal',
                total: tableData.totalElements,
                pageSize: pageSize,
                current: pageNum,
                onChange: paginationChange
              } }
            ></Table>
          </ConfigProvider>
        </Spin>
      </SelectExam>

      <PrintApplyFormTableModal ref={ printApplyFormTableModal }></PrintApplyFormTableModal>
      <PrintAdmissionTicketModal ref={ printAdmissionTicketModal }></PrintAdmissionTicketModal>
      <PrintInterviewTicketModal ref={ printInterviewTicketModal }></PrintInterviewTicketModal>
    </div>
  )
}

export default ExamineePrint
